<script setup lang="ts">
import { vacancyLabelsSchema, VacancyLabelsStyle } from '#imports'

// Inject the block form schema
useBlockFormSchema(vacancyLabelsSchema)
</script>

<template>
  <div class="flex flex-col gap-y-4 pt-4">
    <FormField
      name="title"
      :label="$t('title')"
    />
    <FormField
      name="description"
      type="textarea"
      :label="$t('description')"
    />
    <FormListField
      name="style"
      :label="$t('style')"
      :items="Object.values(VacancyLabelsStyle)"
    />
    <FormField
      name="buttonText"
      :label="$t('buttonText')"
    />
    <FormField
      name="buttonLink"
      :label="$t('buttonLink')"
    />
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Title",
    "description": "Description",
    "style": "Style",
    "buttonText": "Button Text",
    "buttonLink": "Button Link"
  },
  "nl": {
    "title": "Titel",
    "description": "Beschrijving",
    "style": "Stijl",
    "buttonText": "Knop Tekst",
    "buttonLink": "Knop Link"
  }
}
</i18n>
