<script setup lang="ts">
</script>

<template>
  <div>
    <div class="relative flex flex-row gap-2 overflow-hidden py-4">
      <img
        v-for="i in 100"
        :key="i"
        src="/img/mas4you/logo-4-icon.svg"
        alt="Mas4you 4 icoontje"
        class="size-12"
      >
    </div>
  </div>
</template>
