<script setup lang="ts">

const { tenant } = useTenant()

const logo = computed(() => {
  if (tenant.value == 'mas4you') {
    return '/img/mas4you/logo-4-icon.svg'
  }
  // The stagedoos box
  return '/img/stagedoos/logo-icon.svg'
})

</script>

<template>
  <img
    :src="logo"
    alt="Logo"
    class="mb-8 h-32 w-auto"
  >
</template>
