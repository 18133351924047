import { object, string } from 'yup'

export enum VacancyLabelsStyle {
  Default = 'default',
  Alternative = 'alternative',
}

export const vacancyLabelsSchema = object({
  title: string().required(),
  description: string().required(),
  style: string()
    .oneOf(Object.values(VacancyLabelsStyle))
    .default(VacancyLabelsStyle.Default),
  buttonText: string().required(),
  buttonLink: string().required(),
})

export type VacancyLabelsPayload = {
  title: string
  description: string
  style: VacancyLabelsStyle
  buttonText: string
  buttonLink: string
}
