<script setup lang="ts">
// import type { VacancyLabelsPayload } from '#imports'
// const props = defineProps<VacancyLabelsPayload>()

const props = defineProps<{
  title: string
  description: string
  style: VacancyLabelsStyle
  buttonText: string
  buttonLink: string
}>()

// @Maud:
// - Buttons rounding/size is not consistent
// - Colors are not consistent
// - gap-3 instead of gap-4 or gap-2?

// TODO: move this component to the Stagedoos layer?
// const title = 'Stages die bij jou passen'
// const description = 'Vind de stage die bij je past.'

// For Stagedoos, these are named labels, not the categories. Which is a bit unfortunate.
const { labels } = await useLabels()

const vacancySearchPath = '/stagebank?filter[labels_id_in][]='
</script>

<template>
  <ContentBlockViewPartialContainer background-color="primary">
    <div class="flex flex-col gap-y-4">
      <BaseHeading
        as="h2"
        size="4xl"
      >
        {{ title }}
      </BaseHeading>

      <BaseParagraph>
        {{ description }}
      </BaseParagraph>

      <nav v-if="labels" class="my-4 flex flex-row flex-wrap gap-3">
        <NuxtLink
          v-for="label in labels"
          :key="label.id"
          :to="`${vacancySearchPath}${label.id}`"
          class="text-md rounded-[2rem] bg-white/80 px-4 py-2 font-medium text-black hover:bg-white/100"
        >
          {{ label.name }}
        </NuxtLink>
      </nav>

      <div class="flex md:justify-end">
        <NuxtLink
          :to="buttonLink"
          class="text-md hover:bg-primary-400 w-full rounded-[2rem] border border-white bg-transparent px-4 py-2 font-bold text-white md:w-auto"
        >
          {{ buttonText }}
        </NuxtLink>
      </div>
    </div>
  </ContentBlockViewPartialContainer>
</template>
